<template>
  <section>
    <sm_header title='酒店损失' :smHleftEvent='closeWebview'></sm_header>
    <venue-card :demandData="demandData"></venue-card>
    <div class="quotedPrice disabledPrice">
      <van-field label="报价" :readonly="true" v-model="demandData.quote"/>
    </div>
    <div class="quotedPrice disabledPrice">
      <van-field label="损失总金额" :readonly="true" v-model="demandData.amount"/>
    </div>
    <div v-for="(item, index) in demandData.itsExpenses" :key="index">
      <item-card :itemInfo="item" @costItemDetail="costItemDetail"></item-card>
      <div style="width: 100%;height: .08rem;background-color: #f8f8f8"></div>
    </div>

    <div class="fileTitle">
      水单
      <FinderFrame
          :gateway="gateway"
          :OrderByData="OrderByData"
          ref="FinderFrame"
          :isPreview="true"
          :h5FilePreview="false"
          :onlyPreview="true"
          :filterData="uploaderSettings.filterData"
          :mappingData="uploaderSettings.mappingData"
          :isMapping="true"
          :uploadTimeout="120000"
          @uploadCallback="() => {}"
      />
    </div>
    <div class="confirmCostFooter" v-show="isShowFooter">
      <div class="largeBtn2" v-for="(item, index) in btnList" :key="index" :style="{width: (90 / btnList.length)+ '%'}"
         @click="onceAgain(item)">{{item.actName}}</div>
    </div>
  </section>
</template>
<script>
import venueCard from "./components/venueCard";
import itemCard from "./components/itemCard";
import {FinderFrame} from "smart-filer-vue";
import Prompt from '@/components/prompt/plugin';
import "smart-filer-vue/lib/index.css";
import {getCookie} from "tiny-cookie";
import { Field } from 'vant';
export default {
  components: {
    itemCard,
    venueCard,
    FinderFrame,
    [Field.name]: Field,
  },
  data () {
    return {
      taskId: '',
      costInnerItem: {},
      btnList: [{itsActData:{color:null}}],
      uploaderSettings: {
        mappingData: {
          processId: this.$SmartStorage.get('proposalId'),
          relatedId: this.$SmartStorage.get('orderId'),
          relatedType: ["bill","bill_reback","SupplierSettle","settle"],
          catalog: ['损失费文件', '水单']
        },
        filterData: {
          processId: this.$SmartStorage.get('proposalId'),
          relatedId: this.$SmartStorage.get('orderId'),
          relatedType: ["bill","bill_reback","SupplierSettle","settle"],
          catalog: ['损失费文件', '水单']
        }
      },
      demandData: {
        itsQuoteExpense: []
      },
      gateway: process.env.VUE_APP_GATEWAY,
      OrderByData: {
        catalog: 'asc',
        CreatedOn: 'asc'
      },
      tenant: getCookie('tenant') || this.$SmartStorage.get("tenant") || this.$SmartStorage.get("tenant_code")
    }
  },
  created () {
    this.initConfirmCost()
  },
  computed: {
    isShowFooter () {
      let result = this.btnList.length > 0
      return result
    }
  },
  methods: {
    initConfirmCost () {
      this.queryOrderSupplierSettleExpense()
    },
    async queryOrderSupplierSettleExpense () {
      let params = {
        orderId: this.$SmartStorage.get('orderId'),
        proposalId: this.$SmartStorage.get('proposalId'),
        supplierId: this.$SmartStorage.get('supplierId')
      }
      let res = await this.$service.QueryOrderSupplierSettleExpense(params);
      if (res && res.success) {
        this.demandData = res.content || {}
        this.btnList = this.demandData.itsButtons || []
      }
    },
    closeWebview () {
      this.$router.go(-1)
    },
    costItemDetail (item) {
      this.$SmartStorage.set('costInnerItem', item)
      this.$router.push({ path: "/costItemDetailOrder"});
    },
    onceAgain(btn) { // 二次提示
      this[btn.actCode](btn)
    },
    reback (btn) {
      Prompt.show({
        title: "请输入退回原因",
        onConfirm: (val) => {
          this.rebackFun(btn, val)
        }
      })

    },
    async rebackFun (btn, value) {
      let formData = {
        supplierId: this.$SmartStorage.get('supplierId'),
        orderId: this.$SmartStorage.get('orderId'),
      }
      let params = {
        orderId: this.$SmartStorage.get('orderId'),
        proposalId: this.$SmartStorage.get('proposalId'),
        supplierId: this.$SmartStorage.get('supplierId'),
        taskId: this.$SmartStorage.get('taskId'),
        reason: value,
        formData: JSON.stringify(formData),
        taskData: JSON.stringify(Object.assign(formData, {'rebackReason':  value})),
        // 提交 1，退回报价 2，退回结算3，退回损失 4
        operationType: 4
      }
      let res =await this.$service.creatTask(params);
      if (res.success) {
        this.closeWebview();
      }
    },
    confirmLoss (btn) {
      this.$dialog.confirm({
        title: "提示",
        message: `是否确认对本酒店进行${btn.actName}操作`
      }).then(async () => {
        let params = {
          orderId: this.$SmartStorage.get('orderId'),
          processId: this.$SmartStorage.get('proposalId'),
          supplierId: this.$SmartStorage.get('supplierId'),
          nodeId: this.$SmartStorage.get('nodeId'),
          taskId: this.$SmartStorage.get('taskId'),
          actionId: this.$SmartStorage.get('actionId'),
        };
        let res = await this.$service.ProcessTask(params)
        if (res.success) {
          this.$toast("已成功操作");
          this.closeWebview();
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.quotedPriceHead {
  font-size: .25rem;
  text-align: left;
  padding: 0.1rem 0.2rem;
  background: var(--themeColor);
  color: #ffffff;
  font-weight: bold;
}
.headTips {
  background: #F5F5F5;
  margin: .1rem;
  padding: .15rem .18rem;
  font-size: .12rem;
  border-radius: .04rem;
  max-height: 0.2rem;
  overflow: hidden;
  transition: max-height .5s ease;
  p {
    font-size: .12rem;
    text-align: left;
    color: #909399;
  }
  .tipsTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: .15rem;
    color: #13161B;
    margin-bottom: 0.2rem;
  }
}
.headTips.active{
  max-height: 2rem;
}
.fileTitle {
  text-align: left;
  font-size: .18rem;
  font-weight: bold;
  color: #000;
  padding: .1rem;
  position: relative;
  margin-top: .1rem;
}
.confirmCostFooter {
  position: fixed;
  bottom: 0;
  padding: 0.2rem 0.1rem 0.1rem 0.1rem;
  width: calc(100% - 0.2rem);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  background: #fff;
  .largeBtn2 {
    padding: 0.1rem 0;
    border-radius: 0.04rem;
    font-size: .17rem;
    background: #FFFFFF;
    border: 1px solid #E6E7E9;
    font-weight: 500;
    color: #90939A;
    &:last-child{
      background: var(--themeColor);
      border: 1px solid var(--themeColor);
      color: #FFFFFF;
    }
  }
}
</style>
<style lang="scss">
.confirmCostHead {
  .header-bg {
    opacity: 1 !important;
  }
}
.quotedPrice {
  margin: 0 .1rem .2rem;
  border-radius: .04rem;
  .van-cell {
    background: #F5F5F5;
    .van-field__label {
      span {
        font-weight: bold;
      }
    }
    .van-field__control {
      text-align: right;
      font-weight: bold;
    }
  }
}
.disabledPrice {
  .van-cell {
    background: #F5F5F5;
    .van-field__label {
      span{
        color: #909399;
      }
    }
    .van-field__control {
      color: #909399;
    }
  }
}
</style>
