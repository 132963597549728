<template>
  <section>
    <div class="itemInfoBox">
      <div class="itemTitle">
        {{demandData.name}}
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'itemCard',
  props: {
    demandData: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  created() {
  },
  computed: {
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
@import "venueCard";
</style>
